exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apply-index-js": () => import("./../../../src/pages/apply/index.js" /* webpackChunkName: "component---src-pages-apply-index-js" */),
  "component---src-pages-apply-online-js": () => import("./../../../src/pages/apply/online.js" /* webpackChunkName: "component---src-pages-apply-online-js" */),
  "component---src-pages-calculator-js": () => import("./../../../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-clients-reports-js": () => import("./../../../src/pages/clients/reports.js" /* webpackChunkName: "component---src-pages-clients-reports-js" */),
  "component---src-pages-collateral-for-sale-js": () => import("./../../../src/pages/collateral/for-sale.js" /* webpackChunkName: "component---src-pages-collateral-for-sale-js" */),
  "component---src-pages-collateral-index-js": () => import("./../../../src/pages/collateral/index.js" /* webpackChunkName: "component---src-pages-collateral-index-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-frp-about-js": () => import("./../../../src/pages/frp/about.js" /* webpackChunkName: "component---src-pages-frp-about-js" */),
  "component---src-pages-frp-apply-for-grant-js": () => import("./../../../src/pages/frp/apply-for-grant.js" /* webpackChunkName: "component---src-pages-frp-apply-for-grant-js" */),
  "component---src-pages-frp-apply-js": () => import("./../../../src/pages/frp/apply.js" /* webpackChunkName: "component---src-pages-frp-apply-js" */),
  "component---src-pages-frp-grants-js": () => import("./../../../src/pages/frp/grants.js" /* webpackChunkName: "component---src-pages-frp-grants-js" */),
  "component---src-pages-frp-index-js": () => import("./../../../src/pages/frp/index.js" /* webpackChunkName: "component---src-pages-frp-index-js" */),
  "component---src-pages-frp-programs-js": () => import("./../../../src/pages/frp/programs.js" /* webpackChunkName: "component---src-pages-frp-programs-js" */),
  "component---src-pages-frp-programs-priobretenie-oborudovaniya-js": () => import("./../../../src/pages/frp/programs/priobretenie-oborudovaniya.js" /* webpackChunkName: "component---src-pages-frp-programs-priobretenie-oborudovaniya-js" */),
  "component---src-pages-frp-programs-proekty-lesnoi-promyshlennosti-js": () => import("./../../../src/pages/frp/programs/proekty-lesnoi-promyshlennosti.js" /* webpackChunkName: "component---src-pages-frp-programs-proekty-lesnoi-promyshlennosti-js" */),
  "component---src-pages-frp-programs-promyshlennye-obekty-js": () => import("./../../../src/pages/frp/programs/promyshlennye-obekty.js" /* webpackChunkName: "component---src-pages-frp-programs-promyshlennye-obekty-js" */),
  "component---src-pages-frp-steps-js": () => import("./../../../src/pages/frp/steps.js" /* webpackChunkName: "component---src-pages-frp-steps-js" */),
  "component---src-pages-fund-docs-js": () => import("./../../../src/pages/fund/docs.js" /* webpackChunkName: "component---src-pages-fund-docs-js" */),
  "component---src-pages-fund-founders-js": () => import("./../../../src/pages/fund/founders.js" /* webpackChunkName: "component---src-pages-fund-founders-js" */),
  "component---src-pages-fund-index-js": () => import("./../../../src/pages/fund/index.js" /* webpackChunkName: "component---src-pages-fund-index-js" */),
  "component---src-pages-fund-partners-js": () => import("./../../../src/pages/fund/partners.js" /* webpackChunkName: "component---src-pages-fund-partners-js" */),
  "component---src-pages-fund-reports-js": () => import("./../../../src/pages/fund/reports.js" /* webpackChunkName: "component---src-pages-fund-reports-js" */),
  "component---src-pages-fund-services-js": () => import("./../../../src/pages/fund/services.js" /* webpackChunkName: "component---src-pages-fund-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-living-wage-js": () => import("./../../../src/pages/living-wage.js" /* webpackChunkName: "component---src-pages-living-wage-js" */),
  "component---src-pages-programs-js": () => import("./../../../src/pages/programs.js" /* webpackChunkName: "component---src-pages-programs-js" */),
  "component---src-pages-self-employed-js": () => import("./../../../src/pages/self-employed.js" /* webpackChunkName: "component---src-pages-self-employed-js" */),
  "component---src-templates-frp-cooperative-programs-page-template-js": () => import("./../../../src/templates/frp-cooperative-programs-page-template.js" /* webpackChunkName: "component---src-templates-frp-cooperative-programs-page-template-js" */),
  "component---src-templates-news-archive-pagination-js": () => import("./../../../src/templates/news-archive-pagination.js" /* webpackChunkName: "component---src-templates-news-archive-pagination-js" */),
  "component---src-templates-news-markdown-js": () => import("./../../../src/templates/news-markdown.js" /* webpackChunkName: "component---src-templates-news-markdown-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-news-pagination-js": () => import("./../../../src/templates/news-pagination.js" /* webpackChunkName: "component---src-templates-news-pagination-js" */),
  "component---src-templates-program-template-js": () => import("./../../../src/templates/program-template.js" /* webpackChunkName: "component---src-templates-program-template-js" */)
}

