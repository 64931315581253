module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styletron/gatsby-browser.js'),
      options: {"plugins":[],"debug":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Микрокредитная компания Архангельский региональный фонд «Развитие»","short_name":"Фонд \"МКК Развитие\"","start_url":"/","background_color":"#0E799E","theme_color":"#0E799E","display":"minimal-ui","icon":"src/images/cmf-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"27b436f512e0a51268dbf6c40a79d5ca"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
